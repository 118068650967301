<script>
import TasrNewsCategoryNew from './TasrNewsCategoryNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'TasrNewsCategoryEdit',
  extends: TasrNewsCategoryNew,
  methods: {
    getTasrNewsCategory () {
      this.$store.dispatch('tasrNewsCategory/fetchOne', this.$route.params.id)
        .then(() => {
          this.tasrNewsCategory = this.$store.getters['tasrNewsCategory/detail']
          this.loadRubricsBySite()
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('tasrNewsCategory/update', this.tasrNewsCategory)
        .then(() => {
          this.tasrNewsCategory = this.$store.getters['tasrNewsCategory/detail']
          if (this.$store.getters['tasrNewsCategory/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tasrNewsCategory/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getTasrNewsCategory()
  }
}
</script>
